var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.error)?_c('b-message',{attrs:{"type":"is-danger","has-icon":"","icon-size":"is-small"}},[_vm._t("error",[_c('h2',[_vm._v(" "+_vm._s(_vm.$t('error'))+" ")]),_c('p',[_vm._v(" "+_vm._s(_vm.$t('unexpected-error-info-message'))+" ")])])],2):_c('div',{staticClass:"cytomine-table-wrapper"},[_c('b-loading',{attrs:{"is-full-page":false,"active":!_vm.data}}),(_vm.data)?_c('b-table',{attrs:{"data":_vm.data,"loading":_vm.loading,"paginated":_vm.isPaginated,"backend-pagination":"","total":_vm.total,"per-page":_vm.internalPerPage,"current-page":_vm.internalCurrentPage,"pagination-size":"is-small","detailed":_vm.detailed,"detail-key":_vm.detailKey,"opened-detailed":_vm.openedDetailed.slice(),"backend-sorting":"","default-sort":_vm.sort,"default-sort-direction":_vm.order,"checkable":_vm.checkable,"checked-rows":_vm.internalCheckedRows,"is-row-checkable":_vm.isRowCheckable},on:{"update:currentPage":function($event){_vm.internalCurrentPage=$event},"update:current-page":function($event){_vm.internalCurrentPage=$event},"update:openedDetailed":function($event){return _vm.$emit('update:openedDetailed', $event)},"update:checkedRows":function($event){_vm.internalCheckedRows=$event},"update:checked-rows":function($event){_vm.internalCheckedRows=$event},"sort":_vm.updateSort},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_vm._t("header",[_vm._v(" "+_vm._s(column.label)+" ")],{"column":column,"index":_vm.index})]}},{key:"default",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [_vm._t("default",null,{"row":row,"index":index})]}},{key:"detail",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [_vm._t("detail",null,{"row":row,"index":index})]}},{key:"empty",fn:function(){return [_c('div',{staticClass:"content has-text-grey has-text-centered"},[_vm._t("empty",[_vm._v(" "+_vm._s(_vm.$t('no-result'))+" ")],{"row":_vm.row,"index":_vm.index})],2)]},proxy:true},{key:"footer",fn:function(){return [_vm._t("footer")]},proxy:true},{key:"bottom-left",fn:function(){return [_c('b-select',{attrs:{"size":"is-small"},model:{value:(_vm.internalPerPage),callback:function ($$v) {_vm.internalPerPage=$$v},expression:"internalPerPage"}},_vm._l((_vm.perPageOptions),function(option){return _c('option',{key:option,domProps:{"value":option}},[_vm._v(" "+_vm._s(_vm.$t('count-per-page', {count: option}))+" ")])}),0)]},proxy:true}],null,true)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }