<template>
  <div>
    <b-loading :is-full-page="false" :active="loading" />
    <template v-if="!loading">
      <b-message v-if="!clinicalCases" type="is-danger" has-icon icon-size="is-small">
        <h2> {{ $t('error') }} </h2>
        <p> {{ $t('unexpected-error-info-message') }} </p>
      </b-message>
      <template v-else>
        <div class="columns">
          <div class="column is-one-quarter">
            <b-input v-model="searchString" :placeholder="$t('search-placeholder')" type="search" icon="search" />
          </div>

          <div class="column is-one-half has-text-right-desktop">
            <button class="button is-link" @click="startClinicalCaseCreation()">
              {{$t('button-new-clinical-case')}}
            </button>
          </div>
        </div>

        <b-table
          :data="filteredClinicalCases"
          :paginated="true"
          :per-page="perPage"
          pagination-size="is-small"
        >

          <template #default="{row: cc}">
            <b-table-column
              :field="'name'"
              :label="$t('value')"
              sortable
            >
            {{cc.value}}
            </b-table-column>

            <b-table-column
              :field="'creatorName'"
              :label="$t('creator')"
              sortable
            >
              {{cc.creatorName}}
            </b-table-column>

            <b-table-column
              :field="'created'"
              :label="$t('created')"
              sortable
            >
              {{ Number(cc.created) | moment('ll') }}
            </b-table-column>

            <b-table-column label=" " centered>
              <div class="buttons">
                <button class="button is-small is-link" @click="startClinicalCaseEdition(cc)">
                  {{$t('button-edit')}}
                </button>
                <button class="button is-small is-danger" @click="deleteClinicalCaseDialog(cc)">
                  {{$t('button-delete')}}
                </button>
              </div>
            </b-table-column>
          </template>

          <template #empty>
            <div class="content has-text-grey has-text-centered">
              <p>{{$t('no-clinical-case-fitting-criteria')}}</p>
            </div>
          </template>

          <template #bottom-left>
            <b-select v-model="perPage" size="is-small">
              <option value="10">{{$t('count-per-page', {count: 10})}}</option>
              <option value="25">{{$t('count-per-page', {count: 25})}}</option>
              <option value="50">{{$t('count-per-page', {count: 50})}}</option>
              <option value="100">{{$t('count-per-page', {count: 100})}}</option>
            </b-select>
          </template>
        </b-table>

        <clinical-case-modal :active.sync="modal" :clinicalCase="editedClinicalCases" @addClinicalCase="addClinicalCase" @updateClinicalCase="updateClinicalCase" />
      </template>
    </template>
  </div>
</template>

<script>
import {ClinicalCaseCollection} from 'cytomine-client';
import {getWildcardRegexp} from '@/utils/string-utils';
import ClinicalCaseModal from '@/components/tag/ClinicalCaseModal';

export default {
  name: 'admin-clinical-cases',
  components: {
    ClinicalCaseModal
  },
  data() {
    return {
      loading: true,
      clinicalCases: null,
      addClinicalCaseModal: false,
      searchString: '',
      perPage: 25,
      modal: false,
      editedClinicalCases: null
    };
  },
  computed: {
    regexp() {
      return getWildcardRegexp(this.searchString);
    },
    filteredClinicalCases() {
      if(!this.searchString) {
        return this.clinicalCases;
      }

      return this.clinicalCases.filter(ts => this.regexp.test(ts.value));
    }
  },
  methods: {
    startClinicalCaseCreation() {
      this.editedClinicalCases = null;
      this.modal = true;
    },
    addClinicalCase(cc) {
      this.clinicalCases.push(cc);
    },
    startClinicalCaseEdition(cc) {
      this.editedClinicalCases = cc;
      this.modal = true;
    },
    updateClinicalCase(cc) {
      this.editedClinicalCases.populate(cc);
    },

    deleteClinicalCaseDialog(cc) {
      this.$buefy.dialog.confirm({
        title: this.$t('delete'),
        message: this.$t('delete-clinical-case-confirmation-message', {tagName: cc.value}),
        type: 'is-danger',
        confirmText: this.$t('button-confirm'),
        cancelText: this.$t('button-cancel'),
        onConfirm: () => this.deleteClinicalCase(cc)
      });
    },
    deleteClinicalCase(cc) {
      try {
        cc.delete();
        this.clinicalCases.splice(this.clinicalCases.indexOf(cc), 1);
        this.$notify({
          type: 'success',
          text: this.$t('notif-success-clinical-case-delete', {tagName: cc.value})
        });
      }
      catch(error) {
        console.log(error);
        this.$notify({
          type: 'error',
          text: this.$t('notif-error-clinical-case-delete', {tagName: this.currentTag.name})
        });
      }
    },
  },
  async created() {
    try {
      this.clinicalCases = (await ClinicalCaseCollection.fetchAll()).array;
    }
    catch(error) {
      console.log(error);
    }
    this.loading = false;
  }
};
</script>
